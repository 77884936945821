<div
*ngIf="isLoading === true"
class="fixed right-[0%] bottom-[0%] h-[100%] w-[100%] bg-white opacity-75 z-50">
<div class="absolute right-[50%] bottom-[50%] -translate-y-2/4 -translate-x-2/4">
  <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
</div>
</div>

<div class="h-screen flex-auto flex bg-white  ">
  <app-header class="fixed w-full z-50"></app-header>
  <div class="w-full h-full items-center flex-auto 3xl:mt-[208px] 4k:mt-[256px]" style="background-image: linear-gradient(45deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(135deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(0deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(90deg, rgb(3, 20, 35), rgb(76, 9, 223)) !important;">
    <div class="self-center justify-center h-full lg:flex lg:flex-col 3xl:text-3xl 4k:text-5xl">
      
    <div class="w-full h-full drop-shadow-xl flex flex-row ">
      <!-- side menu -->
      <div class="h-full">
        <app-side-menu></app-side-menu>
      </div>
     
      <!-- Form -->
  
    <div class="w-3/6 mt-[10%] ml-[30%] mr-auto ">
      <div class="flex justify-center text-xl">
        <p class="text-[#f2f2f2] pb-[2%]">{{"CREATE_CADET.TITLE" | translate }}</p>
      </div>
      <div class="mb-[5vw] overflow-auto max-h-[calc(100vh-45vh)] ">

        <form [formGroup]="formGroup" class="text-[#ffc601]" (keyup.enter)="onSubmit()">
          <mat-form-field class="w-full text-[#ffc601]">
            <mat-label>{{ "CREATE_CADET.USERNAME" | translate}}</mat-label>
            <input
              type="text"
              matInput
              minlength="2"
              maxlength="30"
              formControlName="username"
            />
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>{{ "CREATE_CADET.EMAIL" | translate}}</mat-label>
            <input type="email" matInput formControlName="email" />
          </mat-form-field>

          <mat-form-field class="w-full 3xl:text-3xl 4k:text-5xl">
            <mat-label class="inline-flex 3xl:text-3xl 4k:text-5xl">
              {{ "CREATE_CADET.ROLE" | translate}}
            </mat-label>
            <mat-select  formControlName="role" class="text-white" >
              
                <mat-option class="text-white" value="TRAINEE">
                  {{ "CREATE_CADET.STANDARD" | translate}}
                </mat-option>
                <mat-option class="text-white" value="TRAINER">
                  {{ "CREATE_CADET.SUPERVISOR" | translate}}
                </mat-option>
                <mat-option class="text-white" value="PILOT_TRAINEE">
                  {{ "CREATE_CADET.PILOT_TRAINEE" | translate}}
                </mat-option>
                <mat-option class="text-white" value="PILOT_TRAINER">
                  {{ "CREATE_CADET.PILOT_TRAINER" | translate}}
                </mat-option>
            </mat-select>
           
          </mat-form-field>
          
          
          <mat-form-field class="w-full 3xl:text-3xl 4k:text-5xl">
            <mat-label class="inline-flex 3xl:text-3xl 4k:text-5xl">
              {{ "CREATE_CADET.PERMISSIONS" | translate}}
            </mat-label>
            <mat-select #permissionsSelect formControlName="permissions" class="text-white" multiple>
              <mat-option class="text-white" value="READ">
                {{ "CREATE_CADET.PERMISSION.READ" | translate}}
              </mat-option>
              <mat-option class="text-white" value="WRITE">
                {{ "CREATE_CADET.PERMISSION.WRITE" | translate}}
              </mat-option>
              <div class="menu-button-container">
                <button mat-raised-button class="exit-button" (click)="exitApplication(permissionsSelect)">
                  OK
                </button>
              </div>
            </mat-select>
          </mat-form-field>
          
          
          <mat-form-field 
          *ngIf="organisation &&  !isGeneralAdmin "
          class="w-full 3xl:text-3xl 4k:text-5xl">
          <mat-label class="inline-flex 3xl:text-3xl 4k:text-5xl">
            {{ "CREATE_CADET.ORGANIZATION_CODE" | translate}}
          </mat-label>
            <mat-select formControlName="organisationDescription" 
            [(ngModel)]="organisation">
              <mat-option class="text-white" value={{organisation}}>
              {{organisation}}
              </mat-option>
            </mat-select>
       
          </mat-form-field>
          <mat-form-field 
          *ngIf="isGeneralAdmin"
          class="w-full 3xl:text-3xl 4k:text-5xl">
            <mat-label class="inline-flex 3xl:text-3xl 4k:text-5xl">
              {{ "CREATE_CADET.ORGANIZATION_CODE" | translate}}
            </mat-label>
            <mat-select #organisationSelect formControlName="organisationDescription" >
              <mat-option class="text-white" value="EUC">
               {{ "CREATE_CADET.ORGANISATION.EUC"|translate}}
              </mat-option>
              <mat-option class="text-white" value="SIMAVI" >
                {{ "CREATE_CADET.ORGANISATION.SIMAVI"|translate}}
              </mat-option>
              <mat-option class="text-white" value="USAL" >
                {{ "CREATE_CADET.ORGANISATION.USAL"|translate}}
              </mat-option>
              <mat-option class="text-white" value="VUB" >
                {{ "CREATE_CADET.ORGANISATION.VUB"|translate}}
              </mat-option>
              <mat-option class="text-white" value="HELVIA" >
                {{ "CREATE_CADET.ORGANISATION.HELVIA"|translate}}
              </mat-option>
              <mat-option class="text-white" value="TECNALIA" >
                {{ "CREATE_CADET.ORGANISATION.TECNALIA"|translate}}
              </mat-option>
              <mat-option class="text-white" value="INNOV" >
                {{ "CREATE_CADET.ORGANISATION.INNOV"|translate}}
              </mat-option>
              <mat-option class="text-white" value="UM" >
                {{ "CREATE_CADET.ORGANISATION.UM"|translate}}
              </mat-option>
              <mat-option class="text-white" value="KEMEA" >
                {{ "CREATE_CADET.ORGANISATION.KEMEA"|translate}}
              </mat-option>
              <mat-option class="text-white" value="AIDEAS" >
                {{ "CREATE_CADET.ORGANISATION.AIDEAS"|translate}}
              </mat-option>
              <mat-option class="text-white" value="TIS" >
                {{ "CREATE_CADET.ORGANISATION.TIS"|translate}}
              </mat-option>
              <mat-option class="text-white" value="CERTH" >
                {{ "CREATE_CADET.ORGANISATION.CERTH"|translate}}
              </mat-option>
              <mat-option class="text-white" value="NEC" >
                {{ "CREATE_CADET.ORGANISATION.NEC"|translate}}
              </mat-option>
              <mat-option class="text-white" value="SQD" >
                {{ "CREATE_CADET.ORGANISATION.SQD"|translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="!isGeneralAdmin"
           class="w-full 3xl:text-3xl 4k:text-5xl ">
            <mat-label class="inline-flex 3xl:text-3xl 4k:text-5xl">
              {{ "CREATE_CADET.COUNTRY" | translate}}
            </mat-label>
            
            <mat-select class="text-white" formControlName="language"
            [(ngModel)]="selected">
              <mat-option class="text-white" value= {{selected}} >
                {{ selected}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
          *ngIf="isGeneralAdmin"
           class="w-full 3xl:text-3xl 4k:text-5xl ">
            <mat-label class="inline-flex 3xl:text-3xl 4k:text-5xl">
              {{ "CREATE_CADET.LANGUAGE" | translate}}
            </mat-label>
            <mat-select class="text-white" #languageSelect formControlName="language">
              <mat-option class="text-white" value="en" >
                {{ "CREATE_CADET.LANGUAGE_CODE.EN"|translate}}
              </mat-option>
              <mat-option class="text-white" value="es">
                {{ "CREATE_CADET.LANGUAGE_CODE.ES"|translate}}
              </mat-option>
              <mat-option class="text-white" value="et">
                {{ "CREATE_CADET.LANGUAGE_CODE.ET"|translate}}
              </mat-option>
              <mat-option class="text-white" value="fr">
                {{ "CREATE_CADET.LANGUAGE_CODE.FR"|translate}}
              </mat-option>
              <mat-option class="text-white" value="lt">
                {{ "CREATE_CADET.LANGUAGE_CODE.LT"|translate}}
              </mat-option>
              <mat-option class="text-white" value="mt">
                {{ "CREATE_CADET.LANGUAGE_CODE.MT"|translate}}
              </mat-option>
              <mat-option class="text-white" value="nl">
                {{ "CREATE_CADET.LANGUAGE_CODE.NL"|translate}}
              </mat-option>
              <mat-option class="text-white" value="ro">
                {{ "CREATE_CADET.LANGUAGE_CODE.RO"|translate}}
              </mat-option>
              <mat-option class="text-white" value="tr">
                {{ "CREATE_CADET.LANGUAGE_CODE.TR"|translate}}
              </mat-option>
              <mat-option class="text-white" value="de">
                {{ "CREATE_CADET.LANGUAGE_CODE.DE"|translate}}
              </mat-option>
              <mat-option class="text-white" value="da">
                {{ "CREATE_CADET.LANGUAGE_CODE.DA"|translate}}
              </mat-option>
              <mat-option class="text-white" value="el">
                {{ "CREATE_CADET.LANGUAGE_CODE.EL"|translate}}
              </mat-option>

              <!-- <div class="menu-button-container">
                <button mat-raised-button class="exit-button" (click)="exitApplication(languageSelect)">
                  OK
                </button>
              </div> -->
            </mat-select>
          </mat-form-field>
       
        </form>
        <div class="text-[10px]">
          * - {{ "LOGIN.ERRORS.REQUIRED" | translate }}
        </div>
        <div *ngFor="let error of formErrors">
          <div
            class="text-[#ffc601]"
            *ngIf="
              (formGroup.get(error.field)!.invalid &&
                formGroup.get(error.field)!.touched) ||
              (formGroup.get(error.field)!.invalid &&
                formGroup.get(error.field)!.dirty)
            "
          >
            <div
              class="text-[10px] 3xl:text-lg 4k:text-3xl"
              *ngIf="
                formGroup.get(error.field)!.invalid &&
                formGroup.get(error.field)!.errors?.['required']
              "
            >
              * {{ error.required | translate }}
            </div>
            <div
              class="text-[10px] 3xl:text-lg 4k:text-3xl"
              *ngIf="
                formGroup.get(error.field)!.invalid &&
                (formGroup.get(error.field)!.errors?.['pattern'] ||
                  formGroup.get(error.field)!.errors?.['minlength'])
              "
            >
              * {{ error.others | translate }}
            </div>
          </div>
        </div>
        <div class="self-center">
          <button
          mat-raised-button
          class="w-auto self-center left-[40%] top-[4%] text-black h-10 bg-[#ffc601] 3xl:text-3xl 3xl:h-10 4xl:h-20 4k:text-5xl"
          (click)="onSubmit()"
          [disabled]="!isFormValid()"
        >
        {{ "CREATE_CADET.BUTTON" | translate}}
        </button>
        </div>
        
       </div>
          
    </div>
     
    </div>
</div>

</div>
