<div
  [ngClass]="
    sidebarVisible === true
      ? 'hidden'
      : 'flex w-full z-50 transition-all min-h-full lg:hidden'
  "
>
  <div
    class="border-t-2 border-[#f2f2f2] h-full  w-1/3 text-black z-50 bg-white" >
  
    <div class="w-full flex flex-col items-center justify-center" >
      <button class="w-full hover:bg-my-yellow bg-white shadow-2xl" style=" box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)" mat-button (click)="goTo('/createUser')">
        {{ "HEADER.HOME" | translate }}
      </button>
      <button class="w-full hover:bg-my-yellow bg-white" mat-button (click)="openDialog()">
        {{ "HEADER.LOGOUT" | translate }}
      </button>
    </div>
  </div>
</div>

<div
  [ngClass]="
   profileBarVisible === true
      ? 'hidden'
      : 'flex items-stretch  w-auto z-50 transition-all min-h-full'
  "
>
  <!-- <div
    class="border-t-2 border-[#f2f2f2] h-full flex items-stretch text-black z-50 bg-white" >
  
    <div class="w-full self-end " >
      <button class="self-end  w-full hover:bg-my-yellow bg-white shadow-2xl" style=" box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)" mat-button (click)="goTo('/createUser')">
        {{ "HEADER.HOME" | translate }}
      </button>
      <button class="self-end w-full hover:bg-my-yellow bg-white shadow-2xl"  mat-button (click)="goTo('/createUser')">
        {{ "HEADER.PROFILE" | translate }}
      </button>
      <button class="self-end  w-full hover:bg-my-yellow bg-white"  mat-button (click)="openDialog()">
        {{ "HEADER.LOGOUT" | translate }}
      </button>
    </div>
  </div>
</div> -->
