import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox'
import {
  USER,
  UserService,
} from 'src/app/services/user/user.service';
import { DeleteUserComponent } from '../delete-user/delete-user.component';
import { BlockUserComponent } from '../block-user/block-user.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { EditUserComponent } from '../edit-user/edit-user.component';



@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css'],
})
export class UsersListComponent implements OnInit {


  languages = [
    { code: 'EN', language: 'English', flag: 'assets/img/EN.png' },
    { code: 'ES', language: 'Spanish', flag: 'assets/img/ES.png' },
    { code: 'FR', language: 'French', flag: 'assets/img/FR.png' },
    { code: 'RO', language: 'Romanian', flag: 'assets/img/RO.png' },
    { code: 'DA', language: 'Danish', flag: 'assets/img/DA.png' },
    { code: 'DE', language: 'German', flag: 'assets/img/DE.png' },
    { code: 'EL', language: 'Greek', flag: 'assets/img/EL.png' },
    { code: 'ET', language: 'Estonian', flag: 'assets/img/ET.png' },
    { code: 'LT', language: 'Lithuanian', flag: 'assets/img/LT.png' },
    { code: 'MT', language: 'Maltese', flag: 'assets/img/MT.png' },
    { code: 'NL', language: 'Dutch', flag: 'assets/img/NL.png' },
    { code: 'TR', language: 'Turkish', flag: 'assets/img/TR.png' },

  ];
  language = this.interactionService.getLanguage();
  username: string = '';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  page: number = 0;
  name: string = '';
  identifier: string = '';
  ELEMENT_DATA: USER[] = [];
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10];
  displayedColumns: string[] = ['username', 'email', 'role', 'permisions', 'language', 'organization_description', 'blocked', 'actions'];
  dataSource: MatTableDataSource<USER> = new MatTableDataSource();
  isPermissionChanged: boolean = false;
  isRoleChanged: boolean = false;
  hasAuthorization: boolean = false;
  // role: any;
  availableRoles: string[] = ['PILOT_TRAINEE', 'PILOT_TRAINER', 'TRAINEE', 'TRAINER'];
  selectedRoleControl = new FormControl(''); // Use an initial value for the selected role
  selectedRole: string = '';

  @ViewChild('readCheckbox') readCheckbox: any;

  @ViewChild('writeCheckbox') writeCheckbox: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    private interactionService: InteractionService,
    private translate: TranslateService,
    private userService: UserService,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private authService: AuthService
  ) {

  }

  ngAfterViewInit() {
    setTimeout(() => (this.dataSource.paginator = this.paginator));
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.getLoggedInUserData().subscribe((res)=>{
   },

   (err:any) =>{
    console.log("eroare" +  err)
   })
   this.interactionService.setNavLocation([
    { name: 'HEADER.HOME', location: '/home' },
    { name: 'HEADER.CADETS_LIST', location: '/viewAll' },
  ]);
    this.getUsers();
    this.isLoading = false;

  }
  onScreenResize() {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    this.getUsers();
  }

  handleCheckboxChange() {
    this.isPermissionChanged = true; // Set the flag to indicate a change
  }

  handleRadioButtonChange() {
    this.isRoleChanged = true;
  }

  updateUserRole(userName: string, selectedRole: string) {

    if (this.isRoleChanged) {
      this.isLoading = true;
      this.userService.updateRole(userName, selectedRole).subscribe(
        (res) => {
          if (res.statusDescription === 'OK' || res.status === true) {
            // location.reload();
            this.openSnackBar(
              this.translate.instant("USER_LIST.REQUEST.ROLE.SUCCESS"),
              'notif-success',
              4000
            );
            this.isLoading = false;
            this.isRoleChanged = false;
          }
          this.isLoading = false;

        },

        (err: any) => {

          if (err.error.statusDescription == "user_have_no_permission") {
            this.isLoading = false;
            this.openSnackBar(
              this.translate.instant("LOGIN.UNAUTHORIZED"),
              'notif-error',
              4000
            );
          } else {
            this.isLoading = false;
            this.openSnackBar(
              this.translate.instant("USER_LIST.REQUEST.ROLE.ERROR"),
              'notif-error',
              4000
            );
          }
          this.isLoading = false;
        }
      );

    }



  }
  getUsers() {
    this.isLoading = true;
    let backend: boolean = true;
    // Fetch to backend
    if (backend) {
      this.userService.getUsersByOrganisation().subscribe(
        (res) => {
          this.hasAuthorization = true;
          this.dataSource.data = res
          this.dataSource.filterPredicate = function (record, filter) {
            return record.userName.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
          }

          this.paginator!.pageIndex = this.currentPage;
          this.paginator!.length = res.count;

          this.isLoading = false;
        },
        (err: any) => {

          if (err.error.statusDescription == "user_have_no_permission") {
            this.isLoading = false;
            this.openSnackBar(
              this.translate.instant("LOGIN.UNAUTHORIZED"),
              'notif-error',
              4000
            );
          } else {
            this.isLoading = false;
            this.openSnackBar(
              this.translate.instant("INTERCEPTOR.SESSION_EXPIRED"),
              'notif-warning',
              4000
            );
          }
        }
      );
    }

    this.isLoading = false;
  }
  onDelete(userName: string) {
    // Check if backend is available or the platform is used only for testing purposes
    this.isLoading = true;
    this.userService.deleteUser(userName).subscribe(
      // If the response is good then deactivate loading spinner from UI and display the success notification
      (res) => {
        if (res.statusDescription === 'OK' || res.status === true) {
          location.reload();
        }
        this.isLoading = false;
      },
      // Otherwise display the error and deactivate the loading spinner
      (err: any) => {
        // Print error in console for development and testing purposes, could be removed
        console.log('ERR:', err);
        // Display a general message for the moment
        // TODO: change error messages accordingly on backend integration

        this.isLoading = false;
      }
    );

  }

  changeCheckbox(event: Event) {
    console.log(event.target);
  }


  onBlock(userName: string) {
    // Check if backend is available or the platform is used only for testing purposes
    this.isLoading = true;
    this.userService.blockUnblockUser(userName).subscribe(
      // If the response is good then deactivate loading spinner from UI and display the success notification
      (res) => {
        if (res.statusDescription === 'OK' || res.status === true) {
          location.reload();

        }
        this.isLoading = false;
      },
      // Otherwise display the error and deactivate the loading spinner
      (err: any) => {
        // Print error in console for development and testing purposes, could be removed
        console.log('ERR:', err);
        // Display a general message for the moment
        // TODO: change error messages accordingly on backend integration

        this.isLoading = false;
      }
    );
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  updatePermissions(element: USER, readChecked: boolean, writeChecked: boolean) {
    // Update the permission values for the user
    if (this.isPermissionChanged) {
      if (readChecked && writeChecked || writeChecked) {
        element.permission = 'WRITE';
      } else {
        element.permission = 'READ';
      }

      this.isLoading = true;

      this.userService.updatePermisions(element.userName, element.permission).subscribe((res) => {
        if (res.statusDescription === 'OK' || res.status === true) {

          this.openSnackBar(
            this.translate.instant("USER_LIST.REQUEST.PERMISSION.SUCCESS"),
            'notif-success',
            4000);

          this.isLoading = false;
          this.isPermissionChanged = false;
        }
        this.isLoading = false;
      },
        // Otherwise display the error and deactivate the loading spinner
        (err: any) => {
          // Print error in console for development and testing purposes, could be removed

          this.openSnackBar(
            this.translate.instant("USER_LIST.REQUEST.PERMISSION.ERROR"),
            'notif-error',
            4000
          );
          // Display a general message for the moment
          // TODO: change error messages accordingly on backend integration

          this.isLoading = false;

        })
    }

  }

  goTo(prefix: string, cadet: USER) {
    this.interactionService.setSelectedUser(cadet);
    this.router.navigate([prefix]);

  }

  openDialogDelete(userName: string) {
    this.isLoading = true;
    this.interactionService.setDeleteUserName(userName);
    this.dialog.open(DeleteUserComponent);
    this.isLoading = false;

  }

  openEditUserDialog(elementBlockStatus: any, elementPermission: any, elementRole: any) {
    this.isLoading = true;

    this.interactionService.setCurrentUserBlockStatus(elementBlockStatus);
    this.interactionService.setCurrentUserPermission(elementPermission);
    this.interactionService.setCurrentUserRole(elementRole);

    this.dialog.open(EditUserComponent);
    this.isLoading = false;
  }
  exitApplication() {

  }

  showOptions(event: MatCheckboxChange): void {
    console.log(event.checked);
  }
  openDialogBlock(userName: string, blocked: boolean) {
    this.isLoading = true;
    this.interactionService.setDeleteUserName(userName);
    this.interactionService.setBlocked(blocked);
    this.dialog.open(BlockUserComponent);
    this.isLoading = false;

  }
  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }

  changeLanguage(code: string, language: string, flag: string) {
    this.language = { code, language, flag };
    localStorage.setItem('language', code);
    this.interactionService.setLanguage({ code, language, flag });
    this.translate.use(
      localStorage.getItem('language')
        ? localStorage.getItem('language')!.toString()
        : 'EN'
    );
  }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }
}
