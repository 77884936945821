<div class="form">
  <div *ngIf="isLoading === true" class="fixed right-[0%] bottom-[0%] h-[100%] w-[100%] bg-white opacity-75 z-50">
    <div class="absolute right-[50%] bottom-[50%] -translate-y-2/4 -translate-x-2/4">
      <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
    </div>
  </div>
  <!-- <h1>IN PROGRESS</h1> -->
  <h2 mat-dialog-title>{{"USER_LIST.ACTION_BUTTONS.EDIT_USER_INFO" | translate}} </h2>
  <form [formGroup]="formGroup" class=" text-[#ffc601] " >

    <!-- <mat-form-field class="w-full text-[#ffc601]">
      <mat-label>{{ "UPDATE_USER.NEW_PERMISSION.PLACEHOLDER_NEW_PERMISSION" | translate}}</mat-label>
      <mat-select formControlName="newPermission" class="text-white" >
        <mat-option class="text-white" value="SOFT">
          {{ "UPDATE_SCENARIO.TYPE.SOFT" | translate}}
        </mat-option>
        <mat-option class="text-white" value="HARD">{{ "UPDATE_SCENARIO.TYPE.HARD" | translate}}</mat-option>
      </mat-select>
    </mat-form-field> -->

    <div class="text-white">
      {{"Update permission"}}
    </div>
    <button mat-raised-button class="menuTrigger" [matMenuTriggerFor]="permissionMenu">
   
      {{ isWriteChecked ? 'READ / WRITE' : 'READ' }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    
    <mat-menu #permissionMenu="matMenu" class="permission-menu" >
      <div layout="row" class="permision-options">
        <div class="permision-text">{{ "CREATE_CADET.PERMISSIONS" | translate }}</div>
        <div class="permision-text">{{ "USER_LIST.UPDATE_PERMISSIONS" | translate }}</div>
       
      </div>
      <hr class="h-px w-[90%] mx-auto my-auto bg-gray-500 border-0 dark:bg-gray-700" />
      <section class="example-section">
        <p class="permision-text">{{ "CREATE_CADET.PERMISSION.READ" | translate }}
          <mat-checkbox #readCheckbox [checked]="true" [disabled]="true" (click)="$event.stopPropagation();"></mat-checkbox>
        </p>
        <p class="permision-text">{{ "CREATE_CADET.PERMISSION.WRITE" | translate }}
          <mat-checkbox #writeCheckbox [checked]="isWriteChecked"  (click)="[$event.stopPropagation(), handleWritePermissionCheck()]"></mat-checkbox>
        </p>
      </section>
      <!-- <button mat-icon-button class="exit-button" (click)="exitApplication()">
          <mat-icon>check_circle</mat-icon>
      </button> -->
      <div class="menu-button-container">
        <button mat-raised-button class="exit-button" >
          OK
        </button>
      </div>
    </mat-menu>
  

    <div class="text-white">
      {{"Update role"}}
    </div>
    <button
      mat-raised-button
      [mat-menu-trigger-for]="roleMenu"
      matTooltip="{{'EDIT_USER' | translate}}"
      matTooltipPosition="above"
      class="menuRole">
      {{profileData.role}}
      <mat-icon>arrow_drop_down</mat-icon>
  </button>

  <mat-menu #roleMenu="matMenu" class="role-menu" >

    <div layout="row" class="permision-options">
      <div class="permision-text">{{ "CREATE_CADET.PERMISSIONS" | translate }}</div>
      <div class="permision-text">{{ "USER_LIST.UPDATE_PERMISSIONS" | translate }}</div>
     
    </div>
    <hr class="h-px w-[90%] mx-auto my-auto bg-gray-500 border-0 dark:bg-gray-700" />
  
    <mat-radio-group [(ngModel)]="profileData.role" >
      <div class="radio-container">
        <mat-radio-button labelPosition="before" *ngFor="let role of availableRoles" [value]="role" class="radio-button" (click)="[$event.stopPropagation() ]">
          <div class="role-option">
            {{ role }}
          </div>
        </mat-radio-button>
      </div>
    </mat-radio-group>
    <div class="menu-button-container">
      <button mat-raised-button class="exit-button">
        OK
      </button>
    </div>
  </mat-menu>


  </form>
</div>
<div class="text-[10px]">
  * - {{ "LOGIN.ERRORS.REQUIRED" | translate }}
</div>
<div *ngFor="let error of formErrors">
  <div class="text-[#ffc601]" *ngIf="
      (formGroup.get(error.field)?.invalid &&
        formGroup.get(error.field)?.touched) ||
      (formGroup.get(error.field)?.invalid &&
        formGroup.get(error.field)?.dirty)
    ">
    <div class="text-[10px] 3xl:text-lg 4k:text-3xl" *ngIf="
        formGroup.get(error.field)?.invalid &&
        formGroup.get(error.field)?.errors?.['required']
      ">
      * {{ error.required | translate }}
    </div>
    <div class="text-[10px] 3xl:text-lg 4k:text-3xl" *ngIf="
        formGroup.get(error.field)?.invalid &&
        (formGroup.get(error.field)?.errors?.['pattern'] ||
          formGroup.get(error.field)?.errors?.['minlength'])
      ">
    
    </div>
    <div class="text-[10px] 3xl:text-lg 4k:text-3xl" *ngIf="
    formGroup.get(error.field)?.invalid &&
    (formGroup.get(error.field)?.errors?.['pattern'] ||
      formGroup.get(error.field)?.errors?.['minlength'])
  ">

</div>
  </div>
</div>
<mat-dialog-actions class="cursor-pointer">
  <button mat-raised-button (click)="onClick()"
    class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded ">Cancel</button>
  <button mat-raised-button [disabled]="!isFormValid()"
    class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded ">OK
  </button>
</mat-dialog-actions>