import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';

import { CreateUserComponent } from './components/create-user/create-user.component';
import { AuthGuard } from './guard/auth.guard';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';


const routes: Routes = [
  { path: '', component: LoginComponent },

  { path: 'login', component: LoginComponent },
  //{ path: 'home', canActivate: [AuthGuard], component: CreateCadetRedirectComponent },
  { path:'createUser', canActivate: [AuthGuard], component: CreateUserComponent },
  { path:'viewAll', canActivate: [AuthGuard], component: UsersListComponent },
  { path:'profile', canActivate: [AuthGuard], component: UserProfileComponent },
  { path: '**', redirectTo: 'viewAll' }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
