import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { USER, UserService } from 'src/app/services/user/user.service';
import {MatListModule} from '@angular/material/list';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-info-card',
  templateUrl: './user-info-card.component.html',
  styleUrls: ['./user-info-card.component.css'],
})
export class UserInfoCardComponent implements OnInit {
  profileData: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };

  isLoading: Boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  isEditMode: boolean = false;
  isEditable = false;

  @ViewChild('firstNameField', { static: false }) firstNameField!: ElementRef;
  @ViewChild('lastNameField', { static: false }) lastNameField!: ElementRef;
  
  updateProfileForm = this._formBuilder.group({
    newFirstName: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(/^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$/),
      ],
    ],
    newLastName: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(/^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$/),
      ],
    ],
  });
  updateProfileFormErrors: { field: string; required: string; others: string }[] = [
    {
      field: 'newFirstName',
      required: 'PROFILE.REQUEST.FIRSTNAME_REQUIRED',
      others: 'PROFILE.REQUEST.FIRSTNAME_PATTERN',
    },
    {
      field: 'newLastName',
      required: 'PROFILE.REQUEST.LASTNAME_REQUIRED',
      others: 'PROFILE.REQUEST.LASTNAME_PATTERN',
    },
  ];



  constructor(private interactionService: InteractionService,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    ) {}

  getProfileData() {
    this.isLoading = true;
    let backend: boolean = true;
    if (backend) {
      this.userService.getLoggedInUserData().subscribe((res)=>{
        this.profileData = res;
     },
     (err:any) =>{
      console.log("eroare" +  err)
     })
     this.isLoading = false;
    }
  }

  ngOnInit(): void {
    this.getProfileData()
  }

  toggleEdit() {
    this.isEditable = true;
  
    if (this.isEditable) {
      setTimeout(() => {
        const container = document.querySelector('.container');
        if (container) {
          const topOffset = container.getBoundingClientRect().top;
          const firstNameFieldOffset = this.firstNameField.nativeElement.offsetTop;
          const lastNameFieldOffset = this.lastNameField.nativeElement.offsetTop;
          const scrollOffset = Math.min(firstNameFieldOffset, lastNameFieldOffset) - topOffset - 20;
          container.scrollTo({ top: scrollOffset, behavior: 'smooth' });
          this.firstNameField.nativeElement.focus();
       
        }
      });
    }
  }
  
  enableEditMode() {
    this.isEditMode = true;
  }
  get newFirstName(){
    return this.updateProfileForm.get('newFirstName');
  }

   get newLastName(){
    return this.updateProfileForm.get('newLastName');
  }

  isFormValid() {
   //console.log(this.updateProfileForm.errors)
    return this.updateProfileForm.valid && this.isEditable;
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }

  onUpdateProfile(){
    if(this.isFormValid()){
      this.isLoading = true;
      this.userService.updateProfile(this.updateProfileForm.get('newFirstName')!.value!, this.updateProfileForm.get('newLastName')!.value!).subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK' || res.status === true) {
            this.isLoading = false;
            //this.interactionService.setPasswordUpdated(true); 
        
            this.interactionService.updateUserProfile(this.updateProfileForm.get('newFirstName')!.value!, this.updateProfileForm.get('newLastName')!.value! )
            this.openSnackBar(
              "Profile updated!",
                'notif-success',
                4000
              );
              location.reload();
          } 
          
        },
        // Otherwise display the error and deactivate the loading spinner
        (err: any) => {
            this.isLoading = false;
            this.openSnackBar(
             "Session expired",
              'notif-warning',
              4000
            );
            this.isLoading = false;
          }
        
      )
    }
  }
  
}
