import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router'
import { InteractionService } from 'src/app/services/interaction.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user/user.service';


@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.css']
})
export class DeleteUserComponent implements OnInit {
  public deleteUserName:string ='';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  constructor(public dialogRef: MatDialogRef<DeleteUserComponent>,
    private router:Router, 
    private interactionService: InteractionService,
    public translate: TranslateService,
    private _snackBar: MatSnackBar,
    private userService: UserService, 
    private authService: AuthService, ) { }

  ngOnInit(): void {
      // if (!localStorage.getItem('admin_user')) {
      //   this.router.navigate(['']);
      // } else {
        this.deleteUserName=this.interactionService.getDeleteUserName()
      // }
  }
    
  onClick(){
    this.dialogRef.close();

  }

  onDelete() {
    this.isLoading=true;
      this.userService.deleteUser(this.deleteUserName)
        .subscribe(
          (res) => {
            if (res.statusDescription === 'OK' && res.statusCode === '001') {
              this.dialogRef.close();
              location.reload();
              this.openSnackBar(
                this.translate.instant('DELETE_USER.SUCCESS'),
                'notif-success',
                4000);
         
            } else{
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("DELETE_USER.ERROR_MESSAGE"),
              'notif-error',
              4000);
            }
            this.isLoading = false;
          },         
          (err: any) => { 
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("INTERCEPTOR.SESSION_EXPIRED"),
              'notif-warning',
              4000
            );
            this.isLoading = false;
          }
        );
  
  }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }
}
