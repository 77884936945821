<div [ngClass]=" isLoading === true ? 'overflow-hidden h-screen flex flex-col': 'h-screen flex flex-col'"
  (window:resize)="onResize($event)">
  <!-- Overlay loading spinner -->
  <div *ngIf="isLoading === true" class="fixed h-full w-full bg-white opacity-75 z-50">
    <div class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4">
      <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
    </div>
  </div>
  <div class="h-screen flex flex-col bg-white">
    <app-header class="fixed w-full z-50"></app-header>
    <div class="flex-auto 3xl:mt-[208px] 4k:mt-[256px]">
      <div class="w-full h-full lg:flex lg:flex-col 3xl:text-3xl 4k:text-5xl">
        <div class="w-full h-full drop-shadow-xl flex flex-row"
          style="background-image: linear-gradient(45deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(135deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(0deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(90deg, rgb(3, 20, 35), rgb(76, 9, 223)) !important;">
          <!-- side menu -->
          <div class="h-full">
            <app-side-menu></app-side-menu>
          </div>
          <div class="w-3/4 mb-[10%] ml-[22%] mr-auto mt-[12%] mb-auto">
            <mat-form-field appearance="standard">
              <input matInput (keyup)="applyFilter($event)" placeholder="{{'SEARCH_BOX.PLACEHOLDER' | translate}}" #input>
            </mat-form-field>
            <div class="flex justify-center text-xl">
              <p class="text-[#f2f2f2] ">{{"USER_LIST.TITLE" | translate }}</p>
            </div>
            <hr>
            <table mat-table [dataSource]="dataSource" class="w-full">
              <ng-container class="pt-[10%] text-3xl" matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef class="w-1/12  3xl:text-3xl 4k:text-5xl">
                  {{"USER_LIST.USERNAME" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="3xl:text-3xl">{{ element.userName }}</td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef class="w-1/12 3xl:text-3xl 4k:text-5xl ">
                  {{"USER_LIST.EMAIL" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="max-w-[16vw] break-words 3xl:text-3xl 4k:text-5xl">
                  {{ element.email }}
                </td>
              </ng-container>
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef class="w-1/12 3xl:text-3xl 4k:text-5xl">
                  {{"USER_LIST.ROLE" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="3xl:text-3xl 4k:text-5xl">
                  {{ element.role }}
                </td>
              </ng-container>
              <ng-container matColumnDef="permisions">
                <th mat-header-cell *matHeaderCellDef class="w-1/12 3xl:text-3xl 4k:text-5xl">
                  {{"USER_LIST.PERMISSIONS" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="3xl:text-3xl 4k:text-5xl">
                  <button mat-raised-button class="menuTrigger" [matMenuTriggerFor]="permissionMenu">
                    {{ element.permission === 'WRITE' ? 'READ / WRITE' : 'READ' }}
                    <mat-icon>arrow_drop_down</mat-icon>
                  </button>
                  <mat-menu #permissionMenu="matMenu" class="permission-menu"
                    (closed)="updatePermissions(element, readCheckbox.checked, writeCheckbox.checked)">
                    <div layout="row" class="permision-options">
                      <div class="permision-text">{{ "CREATE_CADET.PERMISSIONS" | translate }}</div>
                      <div class="permision-text">{{ "USER_LIST.UPDATE_PERMISSIONS" | translate }}</div>
                    </div>
                    <hr class="h-px w-[90%] mx-auto my-auto bg-gray-500 border-0 dark:bg-gray-700" />
                    <section class="example-section">
                      <p class="permision-text">{{ "CREATE_CADET.PERMISSION.READ" | translate }}
                        <mat-checkbox #readCheckbox [checked]="true" [disabled]="true"
                          (click)="$event.stopPropagation();"></mat-checkbox>
                      </p>
                      <p class="permision-text">{{ "CREATE_CADET.PERMISSION.WRITE" | translate }}
                        <mat-checkbox #writeCheckbox [checked]="element.permission === 'WRITE'"
                          (click)="[$event.stopPropagation(), handleCheckboxChange()]"></mat-checkbox>
                      </p>
                    </section>
                    <!-- <button mat-icon-button class="exit-button" (click)="exitApplication()">
                <mat-icon>check_circle</mat-icon>
            </button> -->
                    <div class="menu-button-container">
                      <button mat-raised-button class="exit-button" (click)="exitApplication()">
                        OK
                      </button>
                    </div>
                  </mat-menu>
                </td>
              </ng-container>
              <ng-container matColumnDef="language">
                <th mat-header-cell *matHeaderCellDef class="w-1/12 3xl:text-3xl 4k:text-5xl">
                  {{"USER_LIST.COUNTRY" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="3xl:text-3xl 4k:text-5xl">
                  {{ element.languageCode.toUpperCase() }}
                </td>
              </ng-container>
              <ng-container matColumnDef="organization_description">
                <th mat-header-cell *matHeaderCellDef class="w-1/12 3xl:text-3xl 4k:text-5xl">
                  {{"USER_LIST.ORGANIZATION_DESCRIPTION" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="3xl:text-3xl 4k:text-5xl">
                  {{ element.organizationDescription.toUpperCase() }}
                </td>
              </ng-container>
              <ng-container matColumnDef="blocked">
                <th mat-header-cell *matHeaderCellDef class="w-1/12 3xl:text-3xl 4k:text-5xl">
                  {{"USER_LIST.BLOCKED" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="3xl:text-3xl 4k:text-5xl">
                  <mat-checkbox *ngIf="element.blocked==false"
                    (click)="$event.preventDefault(); openDialogBlock(element.userName, element.blocked)"
                    [checked]="element.blocked" (change)="showOptions($event)"
                    matTooltip="{{ 'BLOCK_USER.TOOLTIP_BLOCK' |translate}}" matTooltipPosition="above" matToolTip>
                  </mat-checkbox>
                  <mat-checkbox *ngIf="element.blocked==true"
                    (click)="$event.preventDefault(); openDialogBlock(element.userName, element.blocked)"
                    [checked]="element.blocked" (change)="showOptions($event)"
                    matTooltip="{{ 'BLOCK_USER.TOOLTIP_UNBLOCK' |translate}}" matTooltipPosition="above">
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="w-2/12 3xl:text-3xl 4k:text-5xl">
                  {{"USER_LIST.ACTIONS" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="3xl:text-3xl 4k:text-5xl">
                  <button mat-button [matMenuTriggerFor]="roleMenu" matTooltip="{{'EDIT_USER' | translate}}"
                    matTooltipPosition="above"
                    class="text-white h-10  hover:text-my-yellow 3xl:text-3xl 3xl:h-10 4xl:h-20 4k:text-5xl">
                    <mat-icon class="actionIcon">edit</mat-icon>
                  </button>
                  <mat-menu #roleMenu="matMenu" class="role-menu"
                    (closed)="updateUserRole(element.userName, element.role)">
                    <div class="change-role-text">{{'USER_LIST.CHANGE_ROLE' | translate}}</div>
                    <hr class="h-px w-[90%] mx-auto my-auto bg-gray-500 border-0 dark:bg-gray-700" />
                    <mat-radio-group [(ngModel)]="element.role">
                      <div class="radio-container">
                        <mat-radio-button *ngFor="let role of availableRoles" [value]="role" class="radio-button"
                          (click)="[$event.stopPropagation(), handleRadioButtonChange() ]">
                          <div class="role-option">
                            {{ role }}
                          </div>
                        </mat-radio-button>
                      </div>
                    </mat-radio-group>
                    <div class="menu-button-container">
                      <button mat-raised-button class="exit-button" (click)="exitApplication()">
                        OK
                      </button>
                    </div>
                  </mat-menu>
                  <button mat-button matTooltip="{{'DELETE_USER.TOOLTIP_DELETE' | translate}}"
                    matTooltipPosition="above"
                    class="text-white h-10 bg- hover:bg-[#0C1B6F] hover:text-my-yellow 3xl:text-3xl 3xl:h-10 4xl:h-20 4k:text-5xl"
                    (click)="openDialogDelete(element.userName)">
                    <mat-icon class="actionIcon">delete</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8">{{"SEARCH_BOX.NO_MATCH" | translate}} "{{input.value}}"</td>
              </tr>
            </table>
            <hr>
            <mat-paginator #paginator [length]="totalRows" [pageSize]="pageSize">
            </mat-paginator>
            <hr>
            <!-- <div *ngIf="dataSource.data.length === 0">No records found</div> -->
          </div>
        </div>
      </div>


    </div>