
<div
  [ngClass]="
    isLoading === true
      ? 'overflow-hidden h-screen flex flex-col'
      : 'h-screen flex flex-col'
  "
  (window:resize)="onResize($event)"
>
  <!-- Overlay loading spinner -->
  <div
    *ngIf="isLoading === true"
    class="fixed h-full w-full bg-white opacity-75 z-50"
  >
    <div
      class="absolute left-[50%] top-[50%] -translate-y-2/4 -translate-x-2/4"
    >
      <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
    </div>
  </div>
  <!-- Content -->
  <div class="flex-auto flex justify-center bg-gradient-to-r from-[#561677] to-[#0C1B6E]">
    <!-- Smaller screens -->
    <div class="flex flex-col lg:hidden xl:hidden h-full">
      <!-- Logo -->
      <div class="w-full my-[10%]">
        <div class="mx-auto bg">
          <img
            class="object-contain w-full h-full"
            src="assets/img/logo_bun.png"
            alt="{{ 'LOGIN.LOGO_ALT' | translate }}"
          />
        </div>
      
      </div>
     
      <!-- Form -->
      <div class="w-2/3 mx-auto p-[5%] justify-center">
        <form [formGroup]="loginFormGroup">
          <mat-form-field  class="w-full">
            <mat-label>{{ "LOGIN.USERNAME" | translate }}</mat-label>
            <input
              type="text"
              matInput
              minlength="2"
              formControlName="username"
            />
          </mat-form-field>
          <mat-form-field  class="w-full">
            <mat-label>{{ "LOGIN.PASSWORD" | translate }}</mat-label>
            <input type="password" matInput formControlName="password" />
          </mat-form-field>
        </form>

        <button
          mat-raised-button
          class="w-[40%] text-black h-10 bg-[#ffc601] "
          (click)="onLogin()"
          [disabled]="!isFormValid()"
      >
        {{ "LOGIN.LOGIN" | translate }}
      </button>
        <div class="text-[10px] text-[#ffc601]">
          * - {{ "LOGIN.ERRORS.REQUIRED" | translate }}
        </div>
        <!-- Errors section-->
        <div *ngFor="let error of loginFormErrors">
          <div
            class="text-[#ffc601]"
            *ngIf="
              (loginFormGroup.get(error.field)!.invalid &&
                loginFormGroup.get(error.field)!.touched) ||
              (loginFormGroup.get(error.field)!.invalid &&
                loginFormGroup.get(error.field)!.dirty)
            ">
            <div
              class="text-[10px] text-[#ffc601]"
              *ngIf="
                loginFormGroup.get(error.field)!.invalid &&
                loginFormGroup.get(error.field)!.errors?.['required']
              "
            >
              * {{ error.required | translate }}
            </div>
            <div
              class="text-[10px] text-[#ffc601]"
              *ngIf="
                loginFormGroup.get(error.field)!.invalid &&
                (loginFormGroup.get(error.field)!.errors?.['pattern'] ||
                  loginFormGroup.get(error.field)!.errors?.['minlength'])
              "
            >
              * {{ error.others | translate }}
            </div>
          </div>
        </div>
        <!-- Other links -->
        <div
          class="my-[10%] text-xs md:text-base flex flex-col space-y-2 text-center justify-center"
        >
          
        </div>
      </div>
    </div>
    <!-- Larger screens -->
    
    <div class="hidden w-full my-auto lg:flex lg:flex-col 3xl:text-xl xl:flex 4k:text-xl ">
      <div class="">
        <!-- Logo -->
        <div class="text-center justify-center ">
          <img
            class="object-contain mx-auto w-3/3 h-full"
            src="assets/img/logo_bun.png"
            alt="{{ 'LOGIN.LOGO_ALT' | translate }}"
          />
          <div class="title">
            <p>Identity Management</p>
          </div>
          <div class="w-1/3 mx-auto p-[5%] justify-center">
            <form [formGroup]="loginFormGroup" class="mt-[10%]" (keyup.enter)="onLogin()">
              <mat-form-field class="w-full text-[#ffc601]">
                <mat-label>{{ "LOGIN.USERNAME" | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  minlength="2"
                  formControlName="username"
                />
              </mat-form-field>
              <mat-form-field class="w-full">
                <mat-label>{{ "LOGIN.PASSWORD" | translate }}</mat-label>
                <input type="password" matInput formControlName="password" />
              </mat-form-field>
            </form>
            <button
              mat-raised-button
              class="w-[40%] text-black h-10 bg-[#ffc601] 3xl:text-2xl 3xl:h-10 3xl:h-12 4k:text-2xl"
              (click)="onLogin()"
              [disabled]="!isFormValid()"
            >
              {{ "LOGIN.LOGIN" | translate }}
            </button>
            <div class="text-[10px] 3xl:text-lg 4k:text-3xl text-[#ffc601]">
              * - {{ "LOGIN.ERRORS.REQUIRED" | translate }}
            </div>
            <!-- Errors section-->
            <div *ngFor="let error of loginFormErrors">
              <div
                class="text-[#ffc601]"
                *ngIf="
                  (loginFormGroup.get(error.field)!.invalid &&
                    loginFormGroup.get(error.field)!.touched) ||
                  (loginFormGroup.get(error.field)!.invalid &&
                    loginFormGroup.get(error.field)!.dirty)
                "
              >
                <div
                  class="text-[10px] 3xl:text-lg 4k:text-3xl"
                  *ngIf="
                    loginFormGroup.get(error.field)!.invalid &&
                    loginFormGroup.get(error.field)!.errors?.['required']
                  "
                >
                  * {{ error.required | translate }}
                </div>
                <div
                  class="text-[10px] 3xl:text-lg 4k:text-3xl "
                  *ngIf="
                    loginFormGroup.get(error.field)!.invalid &&
                    (loginFormGroup.get(error.field)!.errors?.['pattern'] ||
                      loginFormGroup.get(error.field)!.errors?.['minlength'])
                  "
                >
                  * {{ error.others | translate }}
                </div>
              </div>
            </div>
            <!-- Other links -->
            <div
              class="my-[10%] text-xs md:text-base flex flex-col space-y-2 text-center justify-center 3xl:text-2xl 4k:text-4xl"
            >
             
            </div>
          </div>
        </div>
    
        
      </div>
    
    </div>
    
  </div>
  <app-footer></app-footer>
</div>
