
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { UserService } from 'src/app/services/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenService } from 'src/app/services/token/token.service';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css'],
})
export class UpdateProfileComponent implements OnInit{
  updateProfileFormGroup: FormGroup; 
  isLoading: Boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  profileData: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: '',
  };

  constructor(
    _formBuilder: FormBuilder,
    private userService: UserService,
    private _snackBar: MatSnackBar, 
    private translate: TranslateService,
    private interactionService: InteractionService){

    this.updateProfileFormGroup = _formBuilder.group({
      newFirstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$')]],
      newLastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$')]],  
    },);
  }

  onProfileUpdate(){
    if(this.isFormValid()){
      this.isLoading = true;
      this.userService.updateProfile(this.updateProfileFormGroup.get('newFirstName')!.value, this.updateProfileFormGroup.get('newLastName')!.value).subscribe(
        // If the response is good then deactivate loading spinner from UI and display the success notification
        (res) => {
          if (res.statusDescription === 'OK' || res.status === true) {
            this.isLoading = false;
            //this.interactionService.setPasswordUpdated(true); 
        
            // this.interactionService.updateUserProfile(this.updateProfileFormGroup.get('newFirstName')!.value, this.updateProfileFormGroup.get('newLastName')!.value )
 
           
            this.openSnackBar(
              "Profile updated!",
                'notif-success',
                4000
              );
              location.reload();
          } 
          
        },
        // Otherwise display the error and deactivate the loading spinner
        (err: any) => {
            this.isLoading = false;
            this.openSnackBar(
             "Session expired",
              'notif-warning',
              4000
            );
            this.isLoading = false;
          }
        
      )
    }
  }

  ngOnInit(): void {
    this.getProfileData()
  }

  get newFirstName(){
    return this.updateProfileFormGroup.get('newFirstName');
  }

   get newLastName(){
    return this.updateProfileFormGroup.get('newLastName');
  }


  getProfileData() {
    let backend: boolean = true;
    if (backend) {
      this.userService.getLoggedInUserData().subscribe((res)=>{
        this.profileData = res;

     },
     (err:any) =>{
      console.log("eroare")
     })
    }
  }
 
  isFormValid() {
    return this.updateProfileFormGroup.valid;
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }



}
