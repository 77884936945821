
  <div class="xs:h-[5%] lg:h-[5%] xl:h-[5%] flex-1 ">
    <div class="flex flex-row items-center bg-[#ffffff]">
      <div class="w-full h-14 flex space-x-4 items-center justify-center xs:px-2 ">
        <img src="assets/img/EU_logo_small.png" alt="{{ 'FOOTER.ALT_IMG' | translate }}" />
        <div class="text-black leading-snug text-justify text-[10px] xs:text-[12px] lg:text-sm 3xl:text-xl 5xl:text-2xl 4k:text-4xl">
          {{ "FOOTER.CONTENT" | translate }}
        </div>
      </div>
    </div>
  </div>
