<div
*ngIf="isLoading === true"
class="fixed right-[0%] bottom-[0%] h-[100%] w-[100%] bg-white opacity-75 z-50">
  <div class="absolute right-[50%] bottom-[50%] -translate-y-2/4 -translate-x-2/4">
    <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
  </div>
</div>


<div class="w-[50%] flex mx-auto overflow-auto justify-center ">
  <div class="w-full lg:flex lg:flex-col 3xl:text-xl xl:flex 4k:text-xl">
    <div class="text-center justify-center mb-[6vw] overflow-auto max-h-[calc(100vh-60vh)] ">
      
      <mat-card>
        <mat-card-header>
          <mat-card-title class="header-title">{{"PROFILE.ACCOUNT_DETAILS" | translate}}</mat-card-title>
          <div class="edit-icon-container">
            <mat-icon class="edit-icon"
              matTooltip="{{ 'PROFILE.EDIT_TOOLTIP' |translate}}"
              (click)="toggleEdit()"
              matTooltipPosition="above"
              matToolTip>edit</mat-icon>
          </div>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="updateProfileForm" class="mt-[10%]" (keyup.enter)="onUpdateProfile()">
            <div class="container" style="pointer-events: none;">
              <mat-form-field appearance="outline" class="custom-form-field" aria-readonly="true">
                  <mat-label>{{ "LOGIN.USERNAME" | translate }}</mat-label>
                  <input matInput  
                  type="text"
                  id="userName"  
                  minlength="2"
                  value="{{ profileData.userName }}"
                  readonly="true">
              </mat-form-field>
            </div>

            <div class="container" style="pointer-events: none;">
              <mat-form-field appearance="outline" class="custom-form-field" aria-readonly="true">
                  <mat-label>{{"PROFILE.EMAIL" | translate}}</mat-label>
                  <input matInput
                         type="email"
                         id="email"
                         minlength="2"
                         [value]="profileData.email"
                         [readonly]="true"
                         style="cursor: not-allowed;">
              </mat-form-field>
            </div>
  
            <div class="container" [class.editable-container]="isEditable">
              <mat-form-field appearance="outline" class="custom-form-field" 
                [class.editable-outline]="isEditable">
                  <mat-label>{{"PROFILE.FIRSTNAME" | translate}}</mat-label>
                  <input matInput  
                      type="text"
                      id="newFirstName"  
                      minlength="2"
                      maxlength="30"  
                      [value]="profileData.firstName"
                      placeholder='{{"PROFILE.FIRSTNAME_PLACEHOLDER" | translate}}'
                      [readonly]="!isEditable"
                      [(ngModel)]="profileData.firstName" 
                      formControlName="newFirstName"
                      #firstNameField>
              </mat-form-field>
            </div>
              
            <div class="container" [class.editable-container]="isEditable">
              <mat-form-field appearance="outline" class="custom-form-field" 
                [class.editable-outline]="isEditable">
                  <mat-label>{{"PROFILE.LASTNAME" | translate}}</mat-label>
                  <input matInput  
                      type="text"
                      id="newLastName"  
                      minlength="2"
                      maxlength="30"  
                      [value]="profileData.lastName"
                      placeholder='{{"PROFILE.LASTNAME_PLACEHOLDER" | translate}}'
                      [readonly]="!isEditable"
                      [(ngModel)]="profileData.lastName" 
                      formControlName="newLastName"
                      #lastNameField>
  
              </mat-form-field>
            </div>
  
            <div class="container" style="pointer-events: none;">
              <mat-form-field appearance="outline" class="custom-form-field" aria-readonly="true">
                  <mat-label>{{"PROFILE.ROLE" | translate}}</mat-label>
                  <input matInput  
                  type="text"
                  id="role"  
                  minlength="2"
                  value="{{ profileData.role }}"
                  readonly="true">
                </mat-form-field>
            </div>

            <div class="container" style="pointer-events: none;">
              <mat-form-field appearance="outline" class="custom-form-field" aria-readonly="true">
                  <mat-label>{{"PROFILE.PERMISSIONS" | translate}}</mat-label>
                  <input matInput  
                    type="text"
                    id="permissions"  
                    [value]="profileData.permission === 'WRITE' ? 'READ/WRITE' : 'READ'"
                    readonly="true">
              </mat-form-field>
                
            </div>

            <div class="container" style="pointer-events: none;">
              <mat-form-field appearance="outline" class="custom-form-field" aria-readonly="true">
                  <mat-label>{{"PROFILE.ORGANISATION" | translate}}</mat-label>
                  <input matInput  
                    type="text"
                    id="organisation"  
                    [value]="profileData.organizationDescription"
                    readonly="true">
              </mat-form-field>
                
            </div>
        </form>

      </mat-card-content>

      <mat-card-actions class=" mb-[5%] ">
        <button
          mat-raised-button
          class="w-auto text-black h-10 bg-[#ffc601]"
          [disabled]="!isFormValid()"
          (click)="onUpdateProfile()"
        >
        {{"PROFILE.REQUEST.SAVE_PROFILE" | translate}}
        </button>
        <div class="text-[10px] text-[#ffc601]" *ngIf="newFirstName!.touched && newFirstName!.invalid">
          <div *ngIf="newFirstName!.errors?.['required']">*{{"PROFILE.REQUEST.FIRSTNAME_REQUIRED" | translate}}</div>
        <div class="text-[10px] text-[#ffc601]" *ngIf="newFirstName!.errors?.['pattern']">*{{"PROFILE.REQUEST.FIRSTNAME_PATTERN" | translate}}</div>
        </div>
        <div class="text-[10px] text-[#ffc601]" *ngIf="newLastName!.touched && newLastName!.invalid">
          <div *ngIf="newLastName!.errors?.['required']">*{{"PROFILE.REQUEST.LASTNAME_REQUIRED" | translate}}</div>
          <div class="text-[10px] text-[#ffc601]" *ngIf="newLastName!.errors?.['pattern']">*{{"PROFILE.REQUEST.LASTNAME_PATTERN" | translate}}</div>
        </div>

      </mat-card-actions>    
      </mat-card>

    </div>
  </div>
</div>
