  <div
    *ngIf="isLoading === true"
    class="fixed right-[0%] bottom-[0%] h-[100%] w-[100%] bg-white opacity-75 z-50">
    <div class="absolute right-[50%] bottom-[50%] -translate-y-2/4 -translate-x-2/4">
      <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
    </div>
  </div>

<p *ngIf="blocked==false" class="3xl:text-lg 4k:text-3xl">{{ "BLOCK_USER.QUESTION_BLOCK"   | translate }} {{deleteUserName + "?"}}</p>

<p *ngIf="blocked==true" class="3xl:text-lg 4k:text-3xl">{{ "BLOCK_USER.QUESTION_UNBLOCK"   | translate }} {{deleteUserName + "?"}}</p>

<div class="w-[100%] h-[100%]">
  <mat-toolbar class="bg-white w-[100%] h-[100%]">
    <mat-toolbar-row >
      <div
       class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded "
       (click)="onBlock(blocked)">
        {{ "BLOCK_USER.YES" | translate }}
      </div>
      <div class="w-[20%] inline-block "></div>
      <div
      class="w-[40%] cursor-pointer text-white h-8 bg-transparent hover:bg-[#ffc601] text-[#251A60] text-center 3xl:text-lg 4k:text-3xl  hover:text-black   border border-white hover:border-transparent rounded " 
      (click)="onClick()">
        {{ "BLOCK_USER.NO" | translate }}
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

