import { Injectable } from '@angular/core';
import { USER} from './user/user.service';
import { USER_PROFILE } from './profile/profile.service';



@Injectable({
  providedIn: 'root',
})
export class InteractionService {
  private userLanguage: string = '';
  private userProfile: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: ''
  };
  
  private localStorageId = "user_language"
  private isBackendOver = true;
  private blocked: boolean = false;
  private selectedUser: USER | undefined = undefined;
  private deleteUserName =''
  private currentUserBlockStatus: boolean = false;
  private currentUserPermission: string = "";
  private currentUserRole: string = "";
  private passwordUpdated: boolean = false;
  private languageFlag = localStorage.getItem('language')
  ? localStorage.getItem('language')!.toString()
  : 'EN'

  private language = {
    code: localStorage.getItem('language')
      ? localStorage.getItem('language')!.toString()
      : 'EN',
    language: localStorage.getItem('language'),
    flag: 'assets/img/' + this.languageFlag?.toUpperCase() +'.png' 
  };


  public resetVariables() {
    this.username = '';
    //this.language = {code:null, language:null, flag:""};
    localStorage.clear();
  }

  private navLocation: {
    name: string;
    location: string;
  }[] = [];
  private username = '';

  public getLanguage() {
    return this.language;
  }

  public setLanguage(language: {
    code: string;
    language: string;
    flag: string;
  }) {
    this.language = language;
  }
  public setBlocked(blocked:boolean){
    this.blocked = blocked;
  }
  public getBlocked(){
    return this.blocked;
  }

  public setPasswordUpdated(passwordUpdated:boolean){
    this.passwordUpdated = passwordUpdated;
  }
  public getPasswordUpdated(){
    return this.passwordUpdated;
  }

  public getUsername() {
    return this.username;
  }

  public setUsername(username: string) {
    this.username = username;
  }

  public setSelectedUser(user: USER) {
    this.selectedUser = user;
  }

  public getDeleteUserName() {
    return this.deleteUserName;
  }

  public setDeleteUserName(userName: string) {
    this.deleteUserName = userName;
  }

  public getSelectedUser() {
    return this.selectedUser;
  }

  public getNavLocation() {
    return this.navLocation;
  }

  public getCurrentUserRole() {
    return this.currentUserRole;
  }

  public setCurrentUserRole(currentUserRole: string) {
    this.currentUserRole = currentUserRole;
  }

  public getCurrentUserPermission() {
    return this.currentUserPermission;
  }

  public setCurrentUserPermission(currentUserPermission: string) {
    this.currentUserPermission = currentUserPermission;
  }

  public getCurrentUserBlockStatus() {
    return this.currentUserBlockStatus;
  }

  public setCurrentUserBlockStatus(currentUserBlockStatus: boolean) {
    this.currentUserBlockStatus = currentUserBlockStatus;
  }


  public setNavLocation(
    locations: {
      name: string;
      location: string;
    }[]
  ) {
    this.navLocation = locations;
  }

  public getBackendStatus() {
    return this.isBackendOver;
  }

  public getUser(): string{
    // let response: USERDETAILS;
    // if (localStorage.getItem(this.localStorageId+'user')) {
    //   response = JSON.parse(localStorage.getItem(this.localStorageId+'user')!);
    // } else {
    //   response = this.user;
    // }
    return this.userLanguage;
  }

  public setUser(userLanguage: string){
    localStorage.setItem(this.localStorageId, userLanguage);
    this.userLanguage = userLanguage;
  }

  public setUserProfile(profile: USER_PROFILE) {
    this.userProfile = profile;
  }

  public updateUserProfile(firstName:string, lastName:string)
  {
    // let response: USER_PROFILE = this.userProfile;

    this.userProfile.firstName = firstName;
    this.userProfile.lastName = lastName;

    this.setUserProfile(this.userProfile);

    // if (localStorage.getItem(this.localStorageId + 'user_profile')) {
    //   response = JSON.parse(
    //     localStorage.getItem(this.localStorageId + 'user_profile')!
    //   );
    //   response.firstName = firstName
    //   response.lastName = lastName
    //   this.setUserProfile(response)
    // }

  }
  public getUserProfile(): USER_PROFILE {
    // let response: USER_PROFILE;
    // if (localStorage.getItem(this.localStorageId + 'user_profile')) {
    //   response = JSON.parse(
    //     localStorage.getItem(this.localStorageId + 'user_profile')!
    //   );
    // } else {
    //   response = this.userProfile;
    // }
    return this.userProfile;
  }

}
