
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

const lawgameCommunicationAPIURL = environment.lawgameCommunication;

export interface USER {
  userName: string;
  languageCode: string;
  languageDescription: string;
  role: string;
  organizationCode: string;
  organizationDescription: string;
  blocked: boolean;
  permission: string | null;
}



@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}
  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError(error);
  }

  getUsers(): Observable<any> {
   return this.http
      .get<any>(lawgameCommunicationAPIURL + 'com/all')
      .pipe(catchError(UserService.handleError));
  }

  getUsersByOrganisation(): Observable<any> {
   
    return this.http
       .get<any>(lawgameCommunicationAPIURL + 'com/getUsersByOrganization')
       .pipe(catchError(UserService.handleError));
   }
  
  getLoggedInUserData(): Observable<any> {
    return this.http
    .post<any>(lawgameCommunicationAPIURL + 'com/userProfileDetails', null)
    .pipe(catchError(UserService.handleError));
  }

  getUserProfileDetails(): Observable<any> {
    return this.http
      .get<any>(lawgameCommunicationAPIURL + 'com/userProfileDetails')
      .pipe(catchError(UserService.handleError));
  }


  createUser(username: string, role: string, permissions:string, email: string, organizationDescription:string, language:string): Observable<any> {
    
    // add permissions as WRITE if 
    let validPermision = "";
    if(permissions.includes("WRITE")){
        validPermision = "WRITE";
    }else{
      validPermision = "READ";
    }
    
    const body = {
      userName: username,
      role: role,
      permission: validPermision,
      email: email,
      organizationDescription:organizationDescription,
      languageCode:language
    };

    return this.http
      .post<any>(lawgameCommunicationAPIURL + 'com/createUser', body)
      .pipe(catchError(UserService.handleError));
  }


  deleteUser(username:string){
    const body = {
      userName:username
    }
    return this.http
      .post<any>(lawgameCommunicationAPIURL + 'com/deleteUser', body)
      .pipe(catchError(UserService.handleError));
  }

  blockUnblockUser(username:string){
    const body = {
      userName:username
    }
    return this.http.put<any>(lawgameCommunicationAPIURL + 'com/blockUnblockUser', body)
      .pipe(catchError(UserService.handleError));
  }


  updateRole(userName: string, role: string){
    const body ={
      userName: userName,
      role: role
    }
    return this.http.put<any>(lawgameCommunicationAPIURL + 'com/updateRole', body)
    .pipe(catchError(UserService.handleError));
  }

  updatePermisions(userName: string, permission: string | null){
    const body ={
      userName: userName,
      permission: permission
    }
    return this.http.put<any>(lawgameCommunicationAPIURL + 'com/updatePermissions', body)
    .pipe(catchError(UserService.handleError));
  }

  updateProfile(newFirstName:string, newLastName:string){
    const body = {
      newFirstName: newFirstName,
      newLastName: newLastName
    }
    return this.http.put<any>(lawgameCommunicationAPIURL + 'com/userProfile', body)
      .pipe(catchError(UserService.handleError));
  }

  checkToken(){
    return this.http.get<any>(lawgameCommunicationAPIURL + 'com/checkToken')
      .pipe(catchError(UserService.handleError));
  }
}
