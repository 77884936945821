import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LogoutComponent } from 'src/app/components/logout/logout.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
})
export class SidenavComponent implements OnInit {
  @Input() sidebarVisible: boolean = false;

  @Input() profileBarVisible: boolean = false;
  constructor(private router: Router, public dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialog() {
    this.dialog.open(LogoutComponent);
  }

  goTo(prefix: string) {
    this.router.navigate([prefix]);
  }
}
