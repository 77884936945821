import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Lawgame_App';
  constructor(translate: TranslateService) {
    translate.addLangs(['DA', 'DE', 'EN', 'ES', 'EL', 'FR', 'RO', 'TR', "ET", "LT", "NL", "MT"]);
    translate.setDefaultLang('EN');
    if (!localStorage.getItem('user_language')) {
      localStorage.setItem('user_language', 'EN');
    }
    translate.use(
      localStorage.getItem('language')
        ? localStorage.getItem('language')!.toString()
        : 'EN'
    );
  }

}
