import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

type RouterNameMap = {
  [key: string]: string;
};

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css'],
})
export class SideMenuComponent implements OnInit {
 
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
    constructor(
        private router: Router
      ) {}

    ngOnInit(): void {}

    onResize(event: any) {
      this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
    }

    createUser(){
      this.isLoading = true;
    }

    viewAll(){
      this.isLoading = true;
    }
}
