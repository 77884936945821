import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router'
import { InteractionService } from 'src/app/services/interaction.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user/user.service';


@Component({
  selector: 'app-block-user',
  templateUrl: './block-user.component.html',
  styleUrls: ['./block-user.component.css']
})
export class BlockUserComponent implements OnInit {
 

  public deleteUserName:string ='';
  public blocked:boolean=false;
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;

  constructor(public dialogRef: MatDialogRef<BlockUserComponent>,private router:Router, 
    private interactionService: InteractionService,
    public translate: TranslateService,
    private _snackBar: MatSnackBar,
    private userService: UserService ) { }

  ngOnInit(): void {
      // if (!localStorage.getItem('admin_user')) {
      //   this.router.navigate(['']);
      // } else {
        this.deleteUserName=this.interactionService.getDeleteUserName()
        this.blocked=this.interactionService.getBlocked();
      // }
  }

  onClick(){
    this.dialogRef.close();
 
  }

  onBlock(blocked:boolean) {
    this.isLoading=true;
      this.userService.blockUnblockUser(this.deleteUserName)
        .subscribe(
          // If the response is good then deactivate loading spinner from UI and display the success notification
          (res) => {
            if (res.statusDescription === 'OK' && res.statusCode === '001' ) {
              this.dialogRef.close();
              location.reload();
              if(blocked === false){
                this.openSnackBar(
                  this.translate.instant("BLOCK_USER.USER") + this.deleteUserName + this.translate.instant("BLOCK_USER.BLOCKED_SUCCESSFULLY"),
                  'notif-success',
                  4000
                );
              }else{
                this.openSnackBar(
                  this.translate.instant("BLOCK_USER.USER") + this.deleteUserName + this.translate.instant("BLOCK_USER.UNBLOCKED_SUCCESSFULLY"),
                  'notif-success',
                  4000
                );
              }
             
            } else{
             
              this.dialogRef.close();
              this.openSnackBar(
                this.translate.instant("BLOCK_USER.ERROR_MESSAGE"),
                'notif-error',
                4000
              );
            } 
            this.isLoading=false;
          },         
          (err: any) => { 
            this.dialogRef.close();
            this.openSnackBar(
              this.translate.instant("INTERCEPTOR.SESSION_EXPIRED"),
              'notif-warning',
              4000
            );
            this.isLoading = false;
          }
        );
  
  }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }
}
