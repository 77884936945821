import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { USER, UserService } from 'src/app/services/user/user.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {

  language = this.interactionService.getLanguage();
  username: string = '';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;

  profileData: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: '',
    
  };


  constructor(private interactionService: InteractionService,
    private location: Location,
    private userService: UserService ) {}


  ngOnInit(): void {
    this.isLoading=true;
    let backend: boolean = true;
    if (backend) {
      this.userService.getLoggedInUserData().subscribe((res)=>{
        this.profileData = res;
        
     },
     (err:any) =>{
      console.log("eroare" +  err)
     })
     this.interactionService.setNavLocation([
      { name: 'HEADER.HOME', location: '/home' },
      { name: 'HEADER.PROFILE', location: '/profile' },
    ]);
     this.isLoading = false;
    }

    // if (this.interactionService.getPasswordUpdated() === true) {
    //   location.reload(); // Redirect to the login page
    // }
    // this.getProfileData();
  }

  // getProfileData() {
  //   this.isLoading = true;
  //   let backend: boolean = true;

  //   // Fetch to backend
  //   if (backend) {
  //     this.userService.getLoggedInUserData().subscribe((res)=>{
  //       this.profileData = res;

  //    },
  //    (err:any) =>{
  //     console.log("eroare")
  //    })
  //   }
    
  //   this.isLoading = false;
  // }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  goBack() {
    this.location.back();
  }
}
