import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { USER_PROFILE } from 'src/app/services/profile/profile.service';
import { USER, UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css'],
})
export class EditUserComponent implements OnInit {

  language = this.interactionService.getLanguage();
  username: string = '';
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;

  profileData: USER_PROFILE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    languageCode: '',
    organizationDescription: '',
    blocked: false,
    permission: '',
    
  };


  constructor(public dialogRef: MatDialogRef<EditUserComponent>,
    private interactionService: InteractionService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private translate: TranslateService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
   ) {}

    languages = [
      { code: 'EN', language: 'English', flag: 'assets/flags/EN.png'},
      { code: 'ES', language: 'Spanish', flag: 'assets/flags/ES.png'},
      { code: 'FR', language: 'French', flag: 'assets/flags/FR.png'},
      { code: 'RO', language: 'Romanian', flag: 'assets/flags/RO.png'},
      { code: 'DA', language: 'Danish', flag: 'assets/flags/DA.png'},
      { code: 'DE', language: 'German', flag: 'assets/flags/DE.png'},
      { code: 'EL', language: 'Greek', flag: 'assets/flags/EL.png'},
      { code: 'ET', language: 'Estonian', flag: 'assets/flags/ET.png'},
      { code: 'LT', language: 'Lithuanian', flag: 'assets/flags/LT.png'},
      { code: 'MT', language: 'Maltese', flag: 'assets/flags/MT.png'},
      { code: 'NL', language: 'Dutch', flag: 'assets/flags/NL.png'},
      { code: 'TR', language: 'Turkish', flag: 'assets/flags/TR.png'},
    ];

    isWriteChecked: boolean = false;
    availableRoles: string[] = ['TRAINEE', 'TRAINER', 'PILOT_TRAINEE', 'PILOT_TRAINER'];
    formGroup = this._formBuilder.group({
      newRole: ['', Validators.required],
      newBlockStatus: ['', Validators.required],
      newPermission: ['', Validators.required],
    });
  
    formErrors: { field: string; required: string; }[] = [
      {
        field: 'newRole',
        required: 'UPDATE_SCENARIO.NEW_NAME.REQUIRED'
      },
      {
        field: 'newPermission',
        required: 'UPDATE_SCENARIO.DESCRIPTION.REQUIRED',
      },
      {
        field: 'newBlockStatus',
        required: 'UPDATE_SCENARIO.TYPE.REQUIRED',
      },
    ];
  
    isFormValid() {
      return this.formGroup.valid;
    }
  
  
  ngOnInit(): void {
    this.isLoading=true;
    this.userService.checkToken().subscribe((res) =>{
      this.profileData.blocked = this.interactionService.getCurrentUserBlockStatus();
      this.profileData.permission = this.interactionService.getCurrentUserPermission();
      this.profileData.role = this.interactionService.getCurrentUserRole();
      this.isWriteChecked = this.interactionService.getCurrentUserPermission() === "WRITE";
      
    }, (err: any)=>{
      console.log("eroare" + err.error);
    })

    this.interactionService.setNavLocation([
      { name: 'HEADER.HOME', location: '/home' },
      { name: 'HEADER.PROFILE', location: '/profile' },
    ]);

    this.getProfileData();
    this.isLoading = false;
  }

  getProfileData() {
    this.isLoading = true;
    let backend: boolean = true;
    if (backend) {

      this.profileData = this.interactionService.getUserProfile()
    }
    this.isLoading = false;
  }

  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  changeLanguage(code: string, language: string, flag: string) {
    this.language = { code, language, flag };
    localStorage.setItem('language', code);
    this.interactionService.setLanguage({ code, language, flag });
    this.translate.use(
      localStorage.getItem('language')
        ? localStorage.getItem('language')!.toString()
        : 'EN'
    );
  }
  onClick() {
    this.dialogRef.close();

  }

  handleWritePermissionCheck(){
    this.isWriteChecked = !this.isWriteChecked;
  }
}
