import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InteractionService } from 'src/app/services/interaction.service';

@Component({
  selector: 'app-location-navbar',
  templateUrl: './location-navbar.component.html',
  styleUrls: ['./location-navbar.component.css'],
})
export class LocationNavbarComponent implements OnInit {
  pages: {
    name: string;
    location: string;
  }[] = [];
  fields: string[] = [];

  constructor(
    private router: Router,
    private interactionService: InteractionService
  ) {}

  ngOnInit(): void {
    // if (!localStorage.getItem('admin_user')) {
    //   this.router.navigate(['']);
    // } else {
      let organisation = this.interactionService.getUserProfile().organizationDescription;
      if (organisation.length > 1) this.fields.push(organisation);
      this.pages = this.interactionService.getNavLocation();
    // }
  }

  goTo(prefix: string) {
    this.router.navigate([prefix]);
  }
}
