import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/services/interaction.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css'],
})
export class CreateUserComponent implements OnInit {
  
  @ViewChild('permissionsSelect') permissionsSelect!: MatSelect;
  @ViewChild('roleSelect') roleSelect!: MatSelect;
  @ViewChild('organisationSelect') organisationSelect!: MatSelect;
  @ViewChild('languageSelect') languageSelect!: MatSelect;
  
  languages = [
    { code: 'EN', language: 'English', flag: 'assets/img/EN.png' },
    { code: 'ES', language: 'Spanish', flag: 'assets/img/ES.png' },
    { code: 'FR', language: 'French', flag: 'assets/img/FR.png'},
    { code: 'RO', language: 'Romanian', flag: 'assets/img/RO.png' },
    { code: 'DA', language: 'Danish', flag: 'assets/img/DA.png' },
    { code: 'DE', language: 'German', flag: 'assets/img/DE.png' },
    { code: 'EL', language: 'Greek', flag: 'assets/img/EL.png'},
    { code: 'ET', language: 'Estonian', flag: 'assets/img/ET.png'},
    { code: 'LT', language: 'Lithuanian', flag: 'assets/img/LT.png'},
    { code: 'MT', language: 'Maltese', flag: 'assets/img/MT.png'},
    { code: 'NL', language: 'Dutch', flag: 'assets/img/NL.png'},
    { code: 'TR', language: 'Turkish', flag: 'assets/img/TR.png'},

  ];
  language = this.interactionService.getLanguage();

  selected = localStorage.getItem('user_language');
  isGeneralAdmin = this.interactionService.getUserProfile().organizationDescription === "GENERAL";
  organisation:string = ''
  read = "READ"
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  formGroup = this._formBuilder.group({
    username: [
      '',
      [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern(/^(?!^\d+$)(?=.{2,30}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/),
      ],
    ],
    role: ['', Validators.required],
    email: [
      '',
      [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],
    ],
    language: ['', Validators.required],
    organisationDescription:['', Validators.required],
    permissions:['', Validators.required],
  });


  formErrors: { field: string; required: string; others: string }[] = [
    {
      field: 'username',
      required: 'CREATE_CADET.ERRORS.USERNAME.REQUIRED',
      others: 'CREATE_CADET.ERRORS.USERNAME.MINLENGTH',
    },
    {
      field: 'role',
      required: 'CREATE_CADET.ERRORS.ROLE.REQUIRED',
      others: '',
    },
    {
      field: 'email',
      required: 'CREATE_CADET.ERRORS.EMAIL.REQUIRED',
      others: 'CREATE_CADET.ERRORS.EMAIL.PATTERN',
    },
    {
      field: 'language',
      required: 'CREATE_CADET.ERRORS.LANGUAGE.REQUIRED',
      others: '',
    },
    {
      field: 'organisationDescription',
      required: 'CREATE_CADET.ERRORS.ORGANISATION.REQUIRED',
      others: '',
    },
    {
      field: 'permissions',
      required: 'CREATE_CADET.ERRORS.PERMISSION.REQUIRED',
      others: '',
    },
  ];

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private router: Router,
    private interactionService: InteractionService,
    private translate: TranslateService,
    private userService: UserService, 
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
  
    this.userService.getLoggedInUserData().subscribe(
      (res) => {
        this.isGeneralAdmin = res.organizationDescription === 'GENERAL';
        this.organisation = res.organizationDescription;
        this.isLoading = false;
      },
      (err: any) => {
        console.log("error" + err.error);
        this.isLoading = false; // Make sure to handle errors and set isLoading to false
      }
    );

    this.interactionService.setNavLocation([
      { name: 'HEADER.HOME', location: '/home' },
      { name: 'HEADER.CREATE_ACCOUNT', location: '/createUser' },
    ]);
    this.selected = localStorage.getItem('user_language');
    this.isLoading = false;
  }
  

  onSubmit() {
    // Check if backend is available or the platform is used only for testing purposes
    this.isLoading = true;
    if (!this.interactionService.getBackendStatus()) {
      let testingChooseSuccess = true;
      setTimeout(() => {
        // Simulation in case of success:
        if (testingChooseSuccess === true) {
          this.openSnackBar(
            this.translate.instant('CREATE_CADET.REQUEST.SUCCESS'),
            'notif-success',
            4000
          );
          this.router.navigate(['viewAll']);
        }
        // Simulation in case of error:
        else {
          this.openSnackBar(
            this.translate.instant('CREATE_CADET.REQUEST.ERROR'),
            'notif-error',
            4000
          );
        }
        this.isLoading = false;
      }, 1000);
    }
    // Otherwise POST create cadet
    else {

      this.userService.createUser(
          this.formGroup.get('username')!.value!,
          this.formGroup.get('role')!.value!,
          this.formGroup.get('permissions')!.value!,
          this.formGroup.get('email')!.value!,
          this.formGroup.get('organisationDescription')!.value!,
          this.formGroup.get('language')!.value!
        )
        .subscribe(
          // If the response is good then deactivate loading spinner from UI and display the success notification
          (res) => {
            if (res.statusDescription === 'OK' || res.status === true) {
              this.openSnackBar(
                this.translate.instant('CREATE_CADET.REQUEST.SUCCESS'),
                'notif-success',
                4000
              );
              this.router.navigate(['viewAll']);
              this.isLoading = false;
            } 
            
          },
          // Otherwise display the error and deactivate the loading spinner
          (err: any) => {
        
            if(err.error.statusDescription === 'user_has_been_created_but_email_has_not_been_sent'){
              this.openSnackBar(
                this.translate.instant('CREATE_CADET.REQUEST.USER_CREATED_EMAIL_NOT_SENT'),
                'notif-warning',
                4000
              );
              this.router.navigate(['viewAll']);
              this.isLoading = false;
            }
            else if(err.error.statusDescription === 'duplicate_email_or_user'){
              this.openSnackBar(
                this.translate.instant('CREATE_CADET.ERRORS.DUPLICATE_EMAIL_OR_USER'),
                'notif-error',
                4000
              );
              this.isLoading = false;
            }else{
              this.openSnackBar(
                this.translate.instant("INTERCEPTOR.SESSION_EXPIRED"),
                'notif-warning',
                4000
              );
              this.isLoading = false;
            }
         
            this.isLoading = false;
          }
        );
    }
  }

  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration
    
    });
  }

  isFormValid() {
    return this.formGroup.valid;
  }

  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

  changeLanguage(code: string, language: string, flag: string) {
    this.language = { code, language, flag };
    localStorage.setItem('language', code);
    this.interactionService.setLanguage({ code, language, flag });
    this.translate.use(
      localStorage.getItem('language')
        ? localStorage.getItem('language')!.toString()
        : 'EN'
    );
  }
  exitApplication(select: MatSelect) {
    // Perform your exit actions here

    // Close the dropdown
    select.close();
  }

}
