<!-- Header with sidebar menu -->
<div class="drop-shadow-md xs:h-[56px] lg:h-[80px] 3xl:h-[144px] 4k:h-[192px] shadow-2xl">
  <!-- Small screens -->
  <div class="bg-[#0C1B6E]  flex flex-row h-14 lg:hidden" style="
  background-image: linear-gradient(45deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(135deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(0deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(90deg, rgb(3, 20, 35), rgb(76, 9, 223));">
    <!-- Menu -->
    <div class="w-1/5">
      <div class="h-14 flex items-center justify-center" >
      
        <button mat-button >
          <mat-icon class="text-white" (click)="changeSidebarVisibility()">menu</mat-icon>
        </button>
        <h5 class="platform-title">
          Identity Management
       </h5>
      </div>
    </div>

    <!-- Logo -->
    <div class="w-3/5">
      <div class="h-full flex items-center justify-center">
        <img class="object-contain h-full cursor-pointer" src="assets/img/logo_bun.png" alt="{{ 'HEADER.LOGO_ALT' | translate }}"
          (click)="goTo('/viewAll')" />
      </div>
    </div>

    <!-- Language & account -->
    <!-- <div class="w-1/5 ">
      <div class="h-14 flex items-center justify-center">
        <button mat-button [matMenuTriggerFor]="mobileLanguageSelect">
       
        </button>
        <mat-menu  #mobileLanguageSelect="matMenu" yPosition="below" >
          <button mat-menu-item *ngFor="let language of languages"
          (click)="changeLanguage(language.code, language.language, language.flag)">
          <img class="object-contain h-8 w-8 mx-auto" src="{{ language.flag }}" />
        </button>
        </mat-menu>
      </div>
    </div> -->
    
        <!-- Language -->
        <div class="w-1/12">
          <div class="h-18 flex items-center justify-center 3xl:h-36 4k:h-48">
            <mat-form-field class="w-2/3 3xl:text-3xl 4k:text-5xl">
              <mat-label class="flex items-stretch 3xl:text-3xl 4k:text-5xl text-[#FFFFFF]">
                {{ language.code | uppercase }}
                <img class=" h-4 w-4 mx-auto my-auto" src="{{ language.flag }}" />
              </mat-label>
              <mat-select class="overflow-auto scrollbar-hide">
                <mat-option *ngFor="let language of languages" class="flex items-stretch text-[#FFFFFF] "
                  (click)="changeLanguage(language.code, language.language, language.flag)">
                  <div class="flex items-stretch ">
                    {{ language.code | uppercase }}
                    <img class=" h-4 w-4 mx-auto my-auto" src="{{ language.flag }}" />
                  </div>
            
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
  </div>

  <!-- Large screens -->
  
  <div class="hidden w-full my-auto lg:flex lg:flex-col 3xl:text-xl 4k:text-xl "> 
    <div class=" bg-[#0C1B6E]  lg:flex lg:flex-row lg:h-20 3xl:h-36 4k:h-48" style="
    background-image: linear-gradient(45deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(135deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(0deg, rgba(41, 46, 85, 0.2) 0%, rgba(41, 46, 85, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 14.286%, rgba(8, 63, 48, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 28.572%, rgba(91, 21, 141, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 42.858%, rgba(58, 38, 104, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 57.144%, rgba(25, 55, 67, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 71.43%, rgba(108, 13, 159, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 85.716%, rgba(75, 30, 122, 0.2) 100.002%), linear-gradient(90deg, rgb(3, 20, 35), rgb(76, 9, 223));">
      <!-- Logo -->
      <div class="w-2/12">
        <div class="h-20 flex items-center justify-center 3xl:h-36 4k:h-48">
          <img class="object-contain h-16 cursor-pointer 3xl:h-28 4k:h-36" src="assets/img/logo_bun.png" alt="{{ 'HEADER.LOGO_ALT' | translate }}"
            (click)="goTo('/viewAll')" />
        </div>
      </div>
     
  
      <!-- Menu -->
      <div class="w-8/12 px-10">
        <div class="h-24 flex items-center justify-center 3xl:h-36 4k:h-48">
         <h2 class="platform-title">
            Identity Management
         </h2>
        </div>
      </div>
  
      <!-- Language -->
      <div class="w-1/12">
        <div class="h-24 flex items-center justify-center 3xl:h-40 4k:h-48">
          <mat-form-field class="w-2/3 3xl:text-3xl 4k:text-5xl">
            <mat-label class="flex items-stretch 3xl:text-2xl 4k:text-2xl text-[#FFFFFF]">
              {{ language.code | uppercase }}
              <img class="h-4 w-4 mx-auto my-auto 3xl:h-6 3xl:w-6" src="{{ language.flag }}" />
            </mat-label>
            <mat-select class="overflow-auto scrollbar-hide">
              <mat-option *ngFor="let language of languages" class="flex items-stretch text-[#FFFFFF] "
                (click)="changeLanguage(language.code, language.language, language.flag)">
                <div class="flex items-stretch ">
                  {{ language.code | uppercase }}
                  <img class="h-4 w-4 mx-auto my-auto 3xl:h-6 3xl:w-6" src="{{ language.flag }}" />
                </div>
          
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- Profile -->

      <div class="w-1/12 3xl:h-20 3xl:w-2/10 4k:h-38 ">
        <div class="justify-center h-34 3xl:w-20 3xl:ml-6 flex flex-col items-center">
          <div class="h-20 flex items-center justify-center" [matMenuTriggerFor]="belowMenu">
            <button mat-button>
              <img class="h-12 mt-[14%] mx-auto 3xl:h-30 4k:h-36" src="assets/svg/profile.png" alt="{{ 'HEADER.LOGO_ALT' | translate }}" />
              <div class="text-white my-auto mt-[-10%]">{{ username }}</div>
            </button>
            <mat-menu #belowMenu="matMenu" yPosition="below" class="bg-[#ffffff]">
              <button mat-menu-item class="text-[#ffffff]" (click)="goTo('profile')"> {{ "HEADER.PROFILE" | translate }}</button>
              <button mat-menu-item class="text-[#ffffff]" (click)="openDialog()">
                {{ "HEADER.LOGOUT" | translate }} {{"   "}}
                <mat-icon class="text-[#ffffff]">exit_to_app</mat-icon>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      
      
    </div>
  </div>
  
 
  <!-- Location navbar -->
  <app-location-navbar></app-location-navbar>
  <!-- Sidebar menu -->
  <!-- <app-sidenav [sidebarVisible]="sidebarVisible"></app-sidenav> -->
</div>