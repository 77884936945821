<div
*ngIf="isLoading === true"
class="fixed right-[0%] bottom-[0%] h-[100%] w-[100%] bg-white opacity-75 z-50">
  <div class="absolute right-[50%] bottom-[50%] -translate-y-2/4 -translate-x-2/4">
    <app-loading-spinner [diameter]="spinnerSize"></app-loading-spinner>
  </div>
</div>

<div class="w-[50%] flex-auto mx-auto justify-center bg-gradient-to-r from-[#561677] to-[#0C1B6E]">
  <div class="w-full lg:flex lg:flex-col 3xl:text-xl xl:flex 4k:text-xl ">
    <div class="text-center justify-center">
   
      <div class="w-2/3 mx-auto justify-center">
        <div class="title">
          <p>Edit your profile</p>
        </div>
      <form [formGroup]="updateProfileFormGroup" class="mt-[14%]" (keyup.enter)="onProfileUpdate()">
        <mat-form-field class="w-full text-[#ffc601]">
          <mat-label>First name</mat-label>
          <input
            id="newFirstName"
            type="text"
            matInput
            [(ngModel)]="profileData.firstName" 
            minlength="2"
            formControlName="newFirstName"
          />
        </mat-form-field>
    
        <mat-form-field class="w-full text-[#ffc601]">
          <mat-label>Last name</mat-label>
          <input
            id="newLastName"
            type="text"
            matInput
            minlength="2"
            [(ngModel)]="profileData.lastName" 
            formControlName="newLastName"
          />
        </mat-form-field>
        
      </form>

      <button
          mat-raised-button
          class="w-auto text-black h-10 bg-[#ffc601] "
          [disabled]="!isFormValid()"
          (click)="onProfileUpdate()"
      >
       Save profile
      </button>
    <div class="text-[10px] text-[#ffc601]" *ngIf="newFirstName!.touched && newFirstName!.invalid">
      <div *ngIf="newFirstName!.errors?.['required']">*First name is required.</div>
     <div class="text-[10px] text-[#ffc601]" *ngIf="newFirstName!.errors?.['pattern']">*Invalid first name format</div>
    </div>
    <div class="text-[10px] text-[#ffc601]" *ngIf="newLastName!.touched && newLastName!.invalid">
      <div *ngIf="newLastName!.errors?.['required']">*Last name is required.</div>
     
      <div class="text-[10px] text-[#ffc601]" *ngIf="newLastName!.errors?.['pattern']">*Invalid last name format</div>
    </div>
   


  </div>

  
</div>


