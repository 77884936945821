import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { TokenService } from '../services/token/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    private router: Router,
    private _snackBar: MatSnackBar,
  ) {}

  checkLogin(url: string): boolean {
    let response = false;
    if (this.tokenService.getRefreshToken()) {
      response = true;
    } else {
      this.authService.logout();
      this.authService.redirectUrl = url;
      this.router.navigate(['/']).then((_) => false);
    }
    return response;


  }
  openSnackBar(notificationMessage: string, type: string, duration: number) {
    this._snackBar.open(notificationMessage, 'X', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: type,
      duration: duration,
    });
  }
}
