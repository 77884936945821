<div class="hidden w-full  h-6 text-black lg:flex items-center bg-[#f2f2f2]">

  <div class="w-full flex flex-row select-none justify-between">
    <div class="flex flew-row">
      <div *ngFor="let page of pages">
        <button
          mat-button
          (click)="goTo(page.location)"
          class="text-sm 3xl:text-xl 4k:text-3xl">
          {{ page.name | translate | uppercase }}
        </button>
        /
      </div>
    </div>
  </div>

</div>


