import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router'
import { InteractionService } from 'src/app/services/interaction.service';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LogoutComponent>,private router:Router, private interactionService: InteractionService ) { }
  isLoading: boolean = false;
  spinnerSize: number = window.innerWidth > 1920 ? 140 : 70;
  ngOnInit(): void {
  }
  onClick(){
    this.dialogRef.close();
  }
  
  onLogout(){
    this.isLoading = true;
    this.interactionService.resetVariables();
    this.dialogRef.close();
    // location.reload();
    this.router.navigate(['/login']);
    
    this.isLoading = false;
  }
  onResize(event: any) {
    this.spinnerSize = window.innerWidth > 1920 ? 140 : 70;
  }

}
