

<div class="sidebar">
    <div class="menu mt-[42%]">
        <h3>Menu</h3>
        <div class="buttonsWrapper">
            <div class="create">
                <button mat-raised-button (click)="createUser()"  [routerLink]="['/createUser']" routerLinkActive="active" ><mat-icon class="self-center mr-[2%]">person_add</mat-icon>
                    <span >{{'SIDE_MENU.CREATE_CADET' | translate}}</span></button>
                
            </div>
            <div class="view">
                <button mat-raised-button (click)="viewAll()"  [routerLink]="['/viewAll']" routerLinkActive="active" ><mat-icon class="self-center mr-[2%]">group</mat-icon>
                    <span >{{'SIDE_MENU.VIEW_ALL' | translate}}</span></button>
                
            </div>
        </div>
    </div>
    
    
</div>
    <app-footer class="fixed bottom-0 w-full z-10"></app-footer>