import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoadingSpinnerComponent } from './shared-components/loading-spinner/loading-spinner.component';
import { CreateUserComponent } from './components/create-user/create-user.component';

import { SidenavComponent } from './shared-components/sidenav/sidenav/sidenav.component';
import { LocationNavbarComponent } from './shared-components/location-navbar/location-navbar/location-navbar.component';
import { LawgameAppComponent } from './components/lawgame-app/lawgame-app.component';

import { AppMaterialModule } from './app-material/app-material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';

import { CdkColumnDef } from '@angular/cdk/table';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { NgOtpInputModule } from 'ng-otp-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { FormsModule } from '@angular/forms';

import { SideMenuComponent } from './components/side-menu/side-menu.component';


import { UsersListComponent } from './components/users-list/users-list.component';
import { DeleteUserComponent } from './components/delete-user/delete-user.component';
import { BlockUserComponent } from './components/block-user/block-user.component';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { UserProfileComponent } from './components/user-profile/user-profile.component';

import { UserInfoCardComponent } from './components/user-info-card/user-info-card.component';
import { UpdateProfileComponent } from './components/update-profile/update-profile.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { EditUserComponent } from './components/edit-user/edit-user.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    FooterComponent,
    HeaderComponent,
    LoadingSpinnerComponent,
    CreateUserComponent,
    UserProfileComponent,
    SidenavComponent,
    LocationNavbarComponent,
    LawgameAppComponent,
    SideMenuComponent,
    UsersListComponent,
    DeleteUserComponent,
    BlockUserComponent,
    UserInfoCardComponent,
    UpdateProfileComponent, 
    EditUserComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTableModule,
    HttpClientModule,
    MatChipsModule,
    MatExpansionModule,
    FormsModule,
    BrowserModule,
    MatTooltipModule,
    MatGridListModule,
    NgOtpInputModule,
    MatCheckboxModule,
    MatMenuModule,
    MatRadioModule,
    MatListModule,

    
    TranslateModule.forRoot({
      defaultLanguage:'EN',
      loader:{
        provide:TranslateLoader,
        useFactory:HttpLoaderFactory,
        deps:[HttpClient],
      },
    }),
  ],
  providers: [
    CdkColumnDef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
